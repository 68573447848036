import { ApiMarketStatus, MarketInfo, useGetInfo } from '@ping/api';
import { DateTime } from '@ping/utils';

const PAIR_PLACEHOLDER: MarketInfo = {
  amountScale: 0,
  baseAsset: '-',
  baseFee: 0,
  hidden: false,
  id: '-',
  makerFee: 0,
  makerFeeLimit: 0,
  maxPrice: 0,
  minAmount: 0,
  minPrice: 0,
  priceScale: 0,
  quoteAsset: '-',
  quoteFee: 0,
  status: ApiMarketStatus.Open,
  takerFee: 0,
  takerFeeLimit: 0,
};

export const useMarketPair = (instrument: string) => {
  const pairs = useGetInfo({
    query: {
      staleTime: DateTime.days(1),
      select: ({ pairs }) => {
        return new Map(Object.entries(pairs));
      },
    },
  });

  return !pairs.isLoading && pairs.data ? pairs.data.get(instrument) : PAIR_PLACEHOLDER;
};
