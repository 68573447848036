import { clsx } from 'clsx';

import { useGetPortfolio } from '@ping/api';
import { t } from '@ping/helpers';
import { useIsUserLoggedIn, useSelectedCurrency } from '@ping/hooks';
import { format, DateTime } from '@ping/utils';

import style from './style.module.scss';

const AvailableBalance = (props: ICustomizable) => {
  const selectedCurrency = useSelectedCurrency();
  const isUserLoggedIn = useIsUserLoggedIn();
  const { data: portfolio } = useGetPortfolio({
    query: {
      staleTime: DateTime.minutes(5),
      enabled: isUserLoggedIn,
    },
  });

  if (!portfolio) return null;

  return (
    <div className={clsx(style['available-balance'], props.className)}>
      {Boolean(portfolio) && (
        <>
          <div className={style['available-balance__texts']}>
            <div className={style['available-balance__title']}>{t('Available Balance')}</div>
          </div>

          <div className={style['available-balance__amount']}>
            {format.fiat(portfolio?.availableBalance, selectedCurrency) ?? 0}
          </div>
        </>
      )}
    </div>
  );
};

export default AvailableBalance;
