import React from 'react';

import { PortfolioItemResponse, useGetPortfolio } from '@ping/api';
import { useIsUserLoggedIn } from '@ping/hooks';
import { DateTime } from '@ping/utils';

/**
 * It returns a map of portfolio items, where the key is the assetId and the value is the portfolio
 * item
 */
export const usePortfolio = () => {
  const result = React.useRef(new Map<string, PortfolioItemResponse>());

  const isUserLoggedIn = useIsUserLoggedIn();
  const { data: portfolio, isFetched } = useGetPortfolio({
    query: { enabled: isUserLoggedIn, staleTime: DateTime.minutes(5) },
  });

  const [portfolioMap, setPortfolioMap] = React.useState(result.current);

  React.useEffect(() => {
    if (!isFetched || !portfolio) return;

    result.current.clear();
    portfolio.portfolioItems.reduce((map, item) => map.set(item.assetId, item), result.current);
    setPortfolioMap(new Map(result.current));
  }, [isFetched, portfolio]);

  return { portfolio: portfolioMap, isPortfolioFetched: isFetched };
};
