import { parsePair, t } from '@ping/helpers';
import { fixNumber, format } from '@ping/utils';
import { clsx } from 'clsx';

import style from './style.module.scss';

import type { QuoteInfo } from '@ping/api';
import { fixNumberVolume } from '@ping/utils/fix-number.util';

const NOT_AVAILABLE = 'N/A' as const;

/**
 * It determines the fluctuation of a market pair based on the change value.
 * @param pair - The `pair` parameter is an object that represents a market pair.
 */
export const determineMarketPairFluctuation = (pair: { change: number }) => {
  const result = { sign: '', state: 'idle' };

  if (pair?.change > 0) {
    result.sign = '+';
    result.state = 'up';
  }

  if (pair?.change < 0) {
    result.sign = '-';
    result.state = 'down';
  }

  return result;
};

interface ISelectedMarketPairStateProps {
  market: QuoteInfo & {
    change: number;
    changePercent: number;
  };
}

export const SelectedMarketPairState = (props: ISelectedMarketPairStateProps) => {
  const priceFluctuation = determineMarketPairFluctuation(props.market);
  const pair = parsePair.byInstrument(props.market.instrument);

  return (
    <dl className={style['selected-market-pair-state']}>
      <div className={style['selected-market-pair-state__part']}>
        <dt className={style['selected-market-pair-state__title']}>{t('Last price')}</dt>
        <dd className={style['selected-market-pair-state__value']}>
          {isNaN(props.market?.close) ? NOT_AVAILABLE : `${format.number(props.market?.close)} ${pair.quote}`}
        </dd>
      </div>

      <div
        className={clsx(style['selected-market-pair-state__part'], style['selected-market-pair-state__value-center'])}
      >
        <dt className={style['selected-market-pair-state__title']}>{t('24h price')}</dt>
        <dd className={style['selected-market-pair-state__value']} data-fluctuation={priceFluctuation.state}>
          {isNaN(props.market?.changePercent) ? NOT_AVAILABLE : `${fixNumber(props.market?.changePercent)}%`}
        </dd>
      </div>

      <div className={clsx(style['selected-market-pair-state__part'], style['selected-market-pair-state__value-end'])}>
        <dt className={style['selected-market-pair-state__title']}>{t('24h volume')}</dt>
        <dd className={style['selected-market-pair-state__value']}>
          {isNaN(props.market?.volume) ? NOT_AVAILABLE : `${fixNumberVolume(props.market?.volume)} ${pair.base}`}
        </dd>
      </div>
    </dl>
  );
};
