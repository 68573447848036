import { useState } from 'react';
import { useGetPortfolio } from '@ping/api';
import { CryptoTransaction } from '@ping/components';
import { t } from '@ping/helpers';
import { Popup, TableWithVirtualList } from '@ping/uikit';
import { SEND_RECEIVE_TABS } from '@ping/components/CryptoTransaction';
import NoDataIcon from '@ping/assets/Icon/no-data-found.svg';
import { ModalType } from '@user/portfolio/index.page';

import getColumnsList from './getColumnsList';

import style from './style.module.scss';

interface IBalanceTableProps extends IExchangeOrdersTabProps {
  staleTime: number;
}

const BalanceTable = ({ selectedTab, isUserLoggedIn, staleTime, className = '' }: IBalanceTableProps) => {
  const [currentAssetId, setCurrentAssetId] = useState('');
  const [modalType, setModalType] = useState<ModalType>(ModalType.None);
  const [popupActiveTab, setPopupActiveTab] = useState(SEND_RECEIVE_TABS.SEND);
  // Balance
  const { data: portfolio, isLoading: isPortfolioLoading } = useGetPortfolio({
    query: {
      staleTime,
      enabled: isUserLoggedIn ? selectedTab === 'balance' : false,
    },
  });

  const handlePopupClose = () => {
    setModalType(ModalType.None);
    setCurrentAssetId('');
  };

  const getDropDownList = assetId => {
    return [
      {
        title: t('Send'),
        onClick: () => {
          setModalType(ModalType.SendReceive);
          setCurrentAssetId(assetId);
          setPopupActiveTab(SEND_RECEIVE_TABS.RECEIVE);
        },
      },
      {
        title: t('Receive'),
        onClick: () => {
          setModalType(ModalType.SendReceive);
          setCurrentAssetId(assetId);
          setPopupActiveTab(SEND_RECEIVE_TABS.SEND);
        },
      },
    ];
  };

  if (!isPortfolioLoading && (!portfolio?.portfolioItems || portfolio?.portfolioItems?.length === 0)) {
    return (
      <div className='trading-records__no-data-icon'>
        <NoDataIcon />
      </div>
    );
  }

  return (
    <>
      <div className={className}>
        <TableWithVirtualList
          data={portfolio?.portfolioItems || []}
          columns={getColumnsList({ getDropDownList })}
          isLoading={isPortfolioLoading}
          initialState={{}}
          classNameContainer={style['balance-table']}
        />
      </div>

      <Popup
        isOpen={modalType === ModalType.SendReceive}
        title={t('Send or Receive Crypto')}
        onClose={handlePopupClose}
      >
        <CryptoTransaction action={popupActiveTab} assetId={currentAssetId} onCancel={handlePopupClose} />
      </Popup>
    </>
  );
};

export default BalanceTable;
