import InfoIcon from '@ping/assets/Icon/info.svg';
import { t } from '@ping/helpers';
import { Tooltip } from '@ping/uikit/TooltipA11Y';

import style from './style.module.scss';

import type { MarketInfo } from '@ping/api';

interface IFormStructureFeeProps {
  pair: MarketInfo;
  feePercentage: number | string;
}

export const FormStructureFeeRow = (props: IFormStructureFeeProps) => {
  return (
    <div className={style['form-structure-fee-row']}>
      <span className={style['form-structure-fee-row__text']}>
        <span>{t('Fee')}</span>
      </span>
      <Tooltip.Root className={style['form-structure-fee-row__tooltip']} openDelay={0}>
        <Tooltip.Trigger>
          <InfoIcon className={style['form-structure-fee-row__icon']} />
        </Tooltip.Trigger>
        <Tooltip.Content className={style['form-structure-tooltip-content']}>
          <span>{t('Maker: %s%', props.pair.makerFee * 100)}</span>
          <span className={style['form-structure-fee-row__divider']} />
          <span>{t('Taker: %s%', props.pair.takerFee * 100)}</span>
        </Tooltip.Content>
      </Tooltip.Root>
      <span className={style['form-structure-fee-row__text']}>{props.feePercentage}%</span>
    </div>
  );
};
