import React from 'react';
import { useMenuTriggerState } from 'react-stately';
import { clsx } from 'clsx';
import { numericFormatter } from 'react-number-format';
import { t } from '@ping/helpers';
import { ListBox, Popover } from '@ping/uikit';
import { removeNonNumeric } from '@ping/utils';
import { FieldGroup } from '@ping/uikit/FieldGroup';
import { INumberFieldProps } from '@ping/uikit/NumberField';
import { isEqual } from 'lodash-es';
import { useRef, useState } from 'react';
import TriangleArrowDown from '@ping/assets/Icon/triangle-arrow-down.svg';

import style from './style.module.scss';

//
// TODO: Remove it when we have the right api support for the amount/total switching
//       this UI component is not completely finished.
const DISABLE_SELECTABLE_TYPE = true;
const NUMERICS = new Set('9876543210'.split(''));
const OPERATIONS = new Set(['Backspace', 'Delete', 'Tab', 'ArrowRight', 'ArrowLeft']);
const DECIMAL_POINT = '.' as const;
const DECIMAL_COMMA = ',' as const;
const DECIMAL_STARTS_WITH_ZERO = '0.' as const;
const ZERO = '0' as const;

type INumberFieldVariant = 'Amount' | 'Total';

type IAmountNumberFieldProps = INumberFieldProps;

const _AmountNumberField = (props: IAmountNumberFieldProps) => {
  //
  // Inject number field aria attributes
  const inputRef = useRef<HTMLInputElement>();
  // const { labelProps, groupProps, inputProps } = useNumberField(props, fieldState, inputRef);

  //
  // Create menu state with its default values
  const popoverState = useMenuTriggerState({});
  const [selections, setSelections] = useState(new Set<INumberFieldVariant>(['Amount']));
  const selection = [...selections.values()][0];

  const handleOnSelectVariant = (selections: Set<INumberFieldVariant>) => {
    setSelections(selections);
    popoverState.close();
  };

  const normalizeOnKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    const text = inputRef.current.value;

    //
    // allow only valid numeric characters
    const isNumeric = NUMERICS.has(ev.key);
    const isAllowedOperation = OPERATIONS.has(ev.key);
    const isDecimal = text.includes(DECIMAL_POINT);
    const isDecimalPointAllowed = !isDecimal && (DECIMAL_POINT === ev.key || DECIMAL_COMMA === ev.key); // allow only one decimal point
    const isMetaOperation = ev.metaKey || ev.ctrlKey;

    if (!isNumeric && !isDecimalPointAllowed && !isAllowedOperation && !isMetaOperation) {
      ev.preventDefault();
    }
  };

  const handleOnChange = () => {
    const text = removeNonNumeric(inputRef.current.value);

    //
    // prevent more than one leading zero
    const isDuplicateZero = text.startsWith('00');
    if (isDuplicateZero) {
      return;
    }

    //
    // ensures that the input value is always a valid number and prevents errors
    // when performing calculations or using the input value in other parts of the code.
    const isDecimal =
      (text.startsWith(DECIMAL_POINT) && text.endsWith(DECIMAL_POINT)) ||
      (text.startsWith(DECIMAL_COMMA) && text.endsWith(DECIMAL_COMMA));
    if (isDecimal) {
      props.onChange?.('0.');
      return;
    }

    //
    // prevent more than allowed decimals count
    const [, decimal] = text.includes(DECIMAL_COMMA) ? text.split(DECIMAL_COMMA) : text.split(DECIMAL_POINT);
    const isDecimalMaximum = decimal?.length > props.formatOptions?.maximumFractionDigits;
    if (isDecimalMaximum) {
      return;
    }

    //
    // prevent adding integer numbers after zero
    // example:
    // 03   returns  ==>  0
    // 0.3  returns  ==>  0.3
    const isIntegerNumberStartsWithZero = text.startsWith(ZERO) && !text.startsWith(DECIMAL_STARTS_WITH_ZERO);
    if (isIntegerNumberStartsWithZero) {
      props.onChange?.(ZERO);
      return;
    }

    props.onChange?.(text.replace(DECIMAL_COMMA, DECIMAL_POINT));
  };

  const normalizeOnBlur = () => {
    const text = inputRef.current.value;
    //
    // remove tail decimal point
    if (text.endsWith(DECIMAL_POINT) || text.endsWith(DECIMAL_COMMA)) {
      props.onChange?.(text.slice(0, -1));
    }
  };

  return (
    <FieldGroup className={clsx('grow', props.className)}>
      <FieldGroup.Label>
        <Popover type='menu' state={popoverState} placement='bottom start'>
          <Popover.Trigger className={style['label']} isDisabled={props.isDisabled || DISABLE_SELECTABLE_TYPE}>
            <span className={style['label__text']}>{t(selection)}</span>
            {!DISABLE_SELECTABLE_TYPE && <TriangleArrowDown className={style['label__icon']} />}
          </Popover.Trigger>

          <Popover.Panel>
            <ListBox
              className={style['variants-list']}
              label={t('amount / total')}
              disallowEmptySelection
              selectionMode='single'
              selectionBehavior='replace'
              selectedKeys={selections}
              onSelectionChange={handleOnSelectVariant}
            >
              <ListBox.Item
                className={style['variants-list__item']}
                key={t('Amount')}
                textValue={t('Amount')}
                aria-label={t('Amount')}
              >
                {t('Amount')}
              </ListBox.Item>

              <ListBox.Item
                className={style['variants-list__item']}
                key={t('Total')}
                textValue={t('Total')}
                aria-label={t('Total')}
              >
                {t('Total')}
              </ListBox.Item>
            </ListBox>
          </Popover.Panel>
        </Popover>
      </FieldGroup.Label>

      <FieldGroup.Control magnet={props.magnet}>
        <input
          className={style['amount-number']}
          name={selection.toLowerCase()}
          ref={inputRef}
          type='text'
          inputMode='decimal'
          autoComplete='off'
          placeholder={props.placeholder}
          value={numericFormatter(removeNonNumeric(props.value), { thousandSeparator: true })}
          disabled={props.isDisabled}
          readOnly={props.isReadOnly}
          onKeyDown={normalizeOnKeyDown}
          onBlur={normalizeOnBlur}
          onChange={handleOnChange}
        />
      </FieldGroup.Control>
    </FieldGroup>
  );
};

export const AmountNumberField = React.memo(
  _AmountNumberField,
  (prev, next) =>
    prev.isDisabled === next.isDisabled &&
    prev.isReadOnly === next.isReadOnly &&
    prev.value === next.value &&
    prev.minValue === next.minValue &&
    prev.maxValue === next.maxValue &&
    prev.defaultValue === next.defaultValue &&
    isEqual(prev.formatOptions, next.formatOptions)
);
